// exports a hook that tracks the mint ptogress of the nft

import { useState, useEffect } from 'react';
import { useInterval } from './useInterval';
import useTurds from './useTurds';

export const useMinted = () => {
  const [minted, setMinted] = useState(0);

  const getMinted = async () => {
    const minted = await turds.totalSupply();
    setMinted(minted.toString());
  };

  const turds = useTurds()

  useInterval(getMinted, 1000)
  
  useEffect(() => {
    getMinted();
  }, []);

  return {
    minted, 
    supply: 10000
  }
}

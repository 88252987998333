import { Contract, ethers } from "ethers"
import { useEffect, useState } from "react"
import { useProvider, useSigner } from "wagmi"
export default function useTurds() {

  const signer = useSigner()

  const [turds, setTurds] = useState<Contract | undefined>(undefined)

  useEffect(() => {
    if(!signer) return

    const TURDS_ABI = [
      'function totalSupply() public view returns (uint256)',
      'function mint(uint count) public',
      'function paused() public view returns (bool)',
    ]
    const TURDS_ADDRESS = '0x9CB06817653266A2473a2E827Bb8646176023D16'
    // @ts-ignore
    const _turds = new ethers.Contract(TURDS_ADDRESS, TURDS_ABI, signer.data)
    setTurds(_turds)

    return () => {
    }
  }, [signer.data])
  
  return turds as any
}

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
}

const colors = [
  ["#e60049", "UA Red"],
  ["#82b6b9", "Pewter Blue"],
  ["#b3d4ff", "Pale Blue"],
  ["#00ffff", "Aqua"],
  ["#0bb4ff", "Blue Bolt"],
  ["#1853ff", "Blue RYB"],
  ["#35d435", "Lime Green"],
  ["#61ff75", "Screamin Green"],
  ["#00bfa0", "Caribbean Green"],
  ["#ffa300", "Orange"],
  ["#fd7f6f", "Coral Reef"],
  ["#d0f400", "Volt"],
  ["#9b19f5", "Purple X11"],
  ["#dc0ab4", "Deep Magenta"],
  ["#f46a9b", "Cyclamen"],
  ["#bd7ebe", "African Violet"],
  ["#fdcce5", "Classic Rose"],
  ["#FCE74C", "Gargoyle Gas"],
  ["#eeeeee", "Bright Gray"],
  ["#7f766d", "Sonic Silver"],
]

const getRandomColor = () => {
  return colors[getRandomInt(colors.length - 1)][0]
}


 // #FF0000
 const specialHeadFlame = [
  `)`,
  ') \\',
  ` / ) (`,
  `\\(_)/`
]

const specialHeadPoop = [
  `P~O~O~O~P`,
  '\\|/',
  "    _^_    "
]

const specialHeadAtom = [
  `*  *  *`,
  '\\|/',
  "    _^_    "
]

const specialHeadFly = [
  `                        /)               `,
  `                    -:))  BzzzBzBzzzz `,
  `                   _^_  \\)               `,
]

const specialHeads = [
  [specialHeadPoop, getRandomColor()],
  [specialHeadFlame, '#FF0000'],
  [specialHeadAtom, getRandomColor()],
  [specialHeadFly, getRandomColor()]
]


const heads = [
  "    _꒰_    ", "    _#_    ", ` _o_ `, ` _ `, `  _$_  `, `  _♢_  `, "    _ヘ_    "
]
const layerThrees = [
  " (___) ", ` {__#} `, ` (OoO) `, ` [___] `, ` ($_$) `, ` (♢♢♢) `, " (___) "
]
const eyes = ["(o,o)", "(-,-)", "(o,-)", "(o,O)", "(0,0)", "(o-o)" ]
const layerTwos = [
  " (_____) ", ` {__#__} `, ` (oOo0O) `, ` [_____] `, ` ($_$_$) `, ` (♢♢♢♢♢) `, " (_____) "
]
const layerOnes = [
  ` (_______) `, ` {_____#_} `, ` (OOooOO0) `, ` [_______] `, ` ($_$_$_$) `, ` (♢♢♢♢♢♢♢) `, ` (C_O_I_N) `
]

const titles = [
  "SOLID",
  "OLD",
  "FRACTIONALIZED",
  "HARD",
  "MICHELIN",
  "DIAMOND",
  "SHITCOIN",
]

const getRandomHead = () => getRandomInt(heads.length)
const getRandomLayerOne = () => getRandomInt(layerOnes.length)
const getRandomLayerTwo = () => getRandomInt(layerTwos.length)
const getRandomLayerThree = () => getRandomInt(layerThrees.length)
const getRandomSpecialHead = () => getRandomInt(specialHeads.length)

export const generateTurdSvg = (config: any = generateRandom()) => {

  // const decoration = decorations[0] // decorations[getRandomInt(decorations.length)]
  const size = 320

  if(config.special) {
    return [
      `<svg xmlns="http://www.w3.org/2000/svg" width="${320}" height="${320}" viewBox="0 0 ${320} ${320}">`,
      `<rect width="100%" height="100%" fill="#121212"/>`,
      `<text x="160" y="50" font-family="Menlo,monospace" font-weight="700" font-size="20" text-anchor="middle" letter-spacing="1">`,
      `<tspan dy="30" x="160" fill="${config.color || getRandomColor()}" >`,
      'special',
      `</tspan>`,
      `</text>`,
      `</svg>`
    ].join('')
  }

  const decorations = ['#','**', '°´´', '....', '~~']
  const decoration = decorations[getRandomInt(decorations.length)]
  const hasDecoration = getRandomInt(100) < 20
  const base = getRandomInt(layerTwos.length)

  const selectedSpecialhead = specialHeads[config.specialHead] // specialHeads[getRandomInt(specialHeads.length)]
  const selectedSpecialheadLayers = selectedSpecialhead[0] as string[]
  const selectedSpecialheadColor = selectedSpecialhead[1]

  const head = heads[config.head]
  const layerThree = layerThrees[config.layerThree]
  const layerTwo = layerTwos[config.layerTwo]
  const layerOne = layerOnes[config.layerOne]
  const hasTitle = config.hasTitle
  const title = titles[config.title]
  const hasSpecialHead = config.hasSpecialHead

  const bgColor = '#ffa300'


  const svgStr = [
    `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}">`,
    `<rect width="100%" height="100%" fill="#121212"/>`,
    `<text x="160" y="50" font-family="Menlo,monospace" font-weight="700" font-size="20" text-anchor="middle" letter-spacing="1">`,
    hasTitle ? `<tspan dy="15" x="160" font-family="arial" fill="${getRandomColor()}" xml:space="preserve">${title}</tspan>` : '',
      hasSpecialHead
        ? selectedSpecialheadLayers.map((h, i) => `<tspan dy="20" x="160" fill="${selectedSpecialheadColor}" xml:space="preserve">${h}</tspan>`).join('')
        : `<tspan dy="50" x="160" fill="${config.color || getRandomColor()}" xml:space="preserve">${head}</tspan>`,
      `<tspan dy="30" x="160" fill="${config.color || getRandomColor()}" xml:space="preserve">${layerThree}</tspan>`,
      `<tspan dy="30" x="160" fill="${config.color || getRandomColor()}" xml:space="preserve">${layerTwo}</tspan>`,
      `<tspan dy="30" x="160" fill="${config.color || getRandomColor()}" xml:space="preserve">${layerOne}</tspan>`,
    `</text>`,
    // transform="rotate(0 130 25)"
    hasDecoration ? `
    <text style="font-size:12pt;" x="70" transform="rotate(40 160 160)">
      <tspan y="190" fill="red">${decoration}</tspan>
      <tspan y="190" dy="20" fill="red">${decoration}</tspan>
    </text>
    ` : '',
    hasDecoration ? `
    <text style="font-size:12pt;" x="200" transform="rotate(-40 160 160)">
      <tspan y="190" dy="20" fill="red">${decoration}</tspan>
      <tspan y="190"  fill="red">${decoration}</tspan>
    </text>
    ` : '',


    `</svg>`
  ]

  return svgStr.join('')

}

export const generateRandom = () => {

  const isPerfect = getRandomInt(100) < 50
  const hasUniColor = getRandomInt(100) < 10
  const hasSpecialHead = getRandomInt(100) < 20
  const specialHead = getRandomSpecialHead()
  const hasTitle = getRandomInt(100) < 10
  const isSpecial = getRandomInt(100) < 0
  if(isSpecial) {
    return {
      special: true,
    }
  }
  if(isPerfect) {
    const id = getRandomInt(heads.length)
    return {
      head: id,
      layerOne: id,
      layerTwo: id,
      layerThree: id,
      hasTitle,
      title: id,
      hasSpecialHead: hasSpecialHead,
      specialHead,
      color: hasUniColor ? getRandomColor() : undefined
    }
  }
  return {
    head: getRandomHead(),
    layerOne: getRandomLayerOne(),
    layerTwo: getRandomLayerTwo(),
    layerThree: getRandomLayerThree(),
    hasTitle: false,
    hasSpecialHead: hasSpecialHead,
    specialHead,
    color: hasUniColor ? getRandomColor() : undefined
  }
}
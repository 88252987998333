import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { WagmiConfig, createClient, useAccount, useConnect, usePrepareContractWrite, useSigner, configureChains, mainnet, goerli } from 'wagmi'
import { localhost, hardhat } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { BigNumber, ethers, getDefaultProvider } from 'ethers'
import { useMinted } from './hooks/useMinted';
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useCanMint } from './hooks/useCanMint';
import JSConfetti from 'js-confetti'
import useTurds from './hooks/useTurds';
import { generateRandom, generateTurdSvg } from './lib/turds';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet],
  [publicProvider()],
)

const client = createClient({
  autoConnect: true,
  provider,
})


function SvgImage({ config }: { config: any }) {
  const svg = generateTurdSvg(config)
  return <div style={{ width: 320, height: 320 }} dangerouslySetInnerHTML={{ __html: svg }} />
}

function TopNav() {
  const { address, isConnected } = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  function handleConnect() {
    connect()
  }
  const btnDisabled = isConnected
  return (
    <div style={{ height: 50, background: '#dcff3dfa', width: '100%', zIndex: 10, position: 'absolute', color: 'black', fontWeight: 'bold', padding: 10, display: 'flex' }}>

      <button
        disabled={btnDisabled}
        style={{ background: 'none', padding: 5, paddingLeft: 15, paddingRight: 15, fontWeight: 'bold', borderRadius: 5, marginLeft: 'auto', cursor: 'pointer', outline: 'none', border: btnDisabled ? '2px solid #0000003a' : '2px solid black' }}
        onClick={handleConnect}
      >Connect</button>
      <div style={{ marginLeft: 35 }}>
        <div><a href="https://twitter.com/turdswtf" target="_blank" className="p-2 cursor-pointer"><img width="30" alt="etherscan" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/1245px-Twitter-logo.svg.png" className="h-5" /></a></div>
      </div>
      <div style={{ marginLeft: 15 }}>
        <div><a href="https://etherscan.io/address/0x9CB06817653266A2473a2E827Bb8646176023D16" target="_blank" className="p-2 cursor-pointer"><img width="30" alt="etherscan" src="https://etherscan.io/images/brandassets/etherscan-logo-circle.svg" className="h-5" /></a></div>
      </div>
      <div style={{ marginLeft: 15 }}>
        <div><a href="https://opensea.io/collection/turds-wtf" target="_blank" className="p-2 cursor-pointer"><img width="30" alt="opensea" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/OpenSea_icon.svg/2048px-OpenSea_icon.svg.png" className="h-5" /></a></div>
      </div>
    </div>
  )
}

const notify = (str: string) => toast(str, {
  theme: "dark",
});

const notifyError = (str: string) => toast.error(str, {
  theme: "dark",
});

function MintButton({ amount }: { amount: number }) {
  const canMint = useCanMint()
  const signer = useSigner()
  const turds = useTurds()
  const [isLoading, setIsLoading] = useState(false)

  async function handleMint() {

    try {
      if(!signer) return
      if(!turds) return
      setIsLoading(true)
      const tx = await turds.mint(amount)
      const receipt = await tx.wait()

      // console.log('receipt', receipt)
      notify('Successfully minted! Check OpenSea')

      const jsConfetti = new JSConfetti()
      jsConfetti.addConfetti({
        emojis: ['💩'],
        emojiSize: 150,
      })
    } catch (error) {
      notifyError('There was a problem minting. Please try again.')
      console.log('error', error)
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <button
    disabled={!canMint || isLoading}
    onClick={handleMint}
    style={{ fontSize: '3rem', fontWeight: 'bold', borderRadius: 10, cursor: 'pointer', padding: 10 }}>
      {isLoading ? 'Minting...' : '💩FREE MINT💩'}
    </button>
  )
}

function MintTracker() {
  const { minted, supply } = useMinted()

  return (
    <div style={{
      color: '#267bc5',
      fontWeight: 'bold',
      marginTop: 10,
      fontSize: '1.5rem'
    }}>{minted} / {supply} minted</div>
  )
}

const testimonialStyle = {
  background: '#00000066', borderRadius: 10, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5
}

function App() {

  const cursorRef = useRef(null)
  const [mousePos, setMousePos] = useState({})
  const [amount, setAmount] = useState(1)
  const { address, isConnected } = useAccount()
  const MAX_AMOUNT = 25

  const [randomTurds, setRandomTurds] = useState<any[]>([])

  function handleMouseMove(e: any) {
    const clientX = e.clientX;
    const clientY = e.clientY;
    setMousePos({ clientX, clientY })
  }

  useEffect(() => {
    /*
    if (!cursorRef.current) return
    setTimeout(() => {
      // @ts-ignore
      cursorRef.current.style.left = `${mousePos.clientX}px`
      // @ts-ignore
      cursorRef.current.style.top = `${mousePos.clientY + 20}px`
    }, 300)
    */
    return () => {

    }
  }, [mousePos])


  useEffect(() => {
    // generate 50 random turds
    const turds = new Array(50).fill(0).map(() => generateRandom())
    setRandomTurds(turds)
    return () => {

    }
  }, [])

  useEffect(() => {
    if (amount > MAX_AMOUNT) {
      setAmount(MAX_AMOUNT)
    }

    return () => { }
  }, [amount])

  return (
    <WagmiConfig client={client}>

      <div
        className="App"
        onMouseMove={handleMouseMove}
        style={{
          background: '#121212',
          maxHeight: '100vh',
          height: '100vh',
          maxWidth: '100%',
          overflow: 'hidden',
          color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'
        }}>
        <TopNav />
        <ToastContainer />
        <div style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gridTemplateRows: 'repeat(5, 1fr)',
          gap: 20
        }}>
          {
            randomTurds.map((turd, i) => {
              return <SvgImage key={i} config={turd}/>
            })
          }
        </div>

        <div className='testimonials' style={{
        }}>
          <span style={{ transform: 'rotate(10deg)', position: 'absolute', right: 140, top: 150, ...testimonialStyle }}>Multiple layers just like Ethereum</span>
          <span style={{ transform: 'rotate(3deg)', position: 'absolute', right: 80, top: 350, ...testimonialStyle }}>🚜 farm this shit</span>
          <span style={{ transform: 'rotate(-30deg)', position: 'absolute', left: 40, bottom: 300, fontSize: '1.2rem', ...testimonialStyle }}>Somewhere Between 700 Billion And A Trillion 300 Million Billion Combinations</span>
          <span style={{ transform: 'rotate(10deg)', position: 'absolute', bottom: 720, left: 300, fontSize: '2.5rem', ...testimonialStyle }}>A new primitive</span>
          <span style={{ transform: 'rotate(-40deg)', position: 'absolute', bottom: 520, right: 300, fontSize: '2.5rem', ...testimonialStyle }}>For the culture</span>
          <span style={{ transform: 'rotate(-15deg)', position: 'absolute', bottom: 120, right: 300, ...testimonialStyle }}>Female artist (actually true)</span>
          <span style={{ transform: 'rotate(-3deg)', position: 'absolute', left: 40, top: 190, ...testimonialStyle }}>Shitcoins with images</span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', width: '100%', height: '100vh' }}>
          <div style={{ padding: '3rem', marginTop: 20 }}>
            <h2>💩💩💩 HOPE YOU LIKE FREE SHIT 💩💩💩</h2>
          </div>
          <div style={{
            background: '#00000033',
            padding: 20,
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 99,
            backdropFilter: 'blur(5px)',
            margin: '1rem',
          }}>
            <div style={{ padding: '2rem', textAlign: 'center' }}>
              <div style={{ fontSize: '2rem' }}>10k generative onchain 💩</div>
              <div>A shitstorm ON Ethereum</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, alignItems: 'center' }}>
              <div style={{ fontSize: '2rem', display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginRight: '1rem', }}>
                  <div style={{ width: '10rem' }}>Amount</div>
                  <div style={{ fontSize: '1.3rem' }}>({MAX_AMOUNT} MAX)</div>
                </div>

                <input type="text" style={{ fontSize: '2rem', borderRadius: 5, padding: 10, width: 200 }}
                  pattern="[0-9]*"
                  value={amount}
                  onChange={(e) =>
                    setAmount((v: any) => (e.target.validity.valid ? e.target.value : v))
                  }
                ></input></div>
              <div style={{ marginTop: 50 }}>
                <MintButton amount={amount}/>
              </div>
              <div style={{ marginTop: 10 }}>Will YOU mint the rarest token, Anon?</div>
              <MintTracker />
            </div>
          </div>
        </div>
      </div>
    </WagmiConfig>
  );
}

export default App;

import React from "react";

export const useInterval = (callback: any, delay = 100) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {

    function tick() {
      if(!savedCallback || !savedCallback.current) return
      // @ts-ignore
      savedCallback.current();
    }
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, []);
};
// exports a hook that tracks the mint ptogress of the nft

import { useState, useEffect } from 'react';
import { useInterval } from './useInterval';
import useTurds from './useTurds';

export const useIsPaused = () => {
  const [isPaused, setPaused] = useState(true);

  const getPaused = async () => {
    const _isPaused = await turds.paused()
    setPaused(_isPaused);
  };

  const turds = useTurds()

  useInterval(getPaused, 1000)
  
  useEffect(() => {
    getPaused();
  }, []);

  return {
    isPaused, 
  }
}

import { useEffect, useState } from "react"
import { useAccount } from "wagmi"
import { useIsPaused } from "./useIsPaused"
import { useMinted } from "./useMinted"

export function useCanMint() {
  const { address, isConnected } = useAccount()
  const {minted, supply} = useMinted()
  const [canMint, setCanMint] = useState(false)
  const { isPaused } = useIsPaused()

  useEffect(() => {
    console.log('useCanMint', isConnected, minted, supply, typeof isPaused)
    setCanMint(
      isConnected
      && minted <= supply
      // @ts-ignore
      && isPaused === false
    )
  
    return () => {
      
    }
  }, [isConnected, isPaused])
  

  return canMint
}
